::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  ::-webkit-scrollbar-track {
    background: #FFE5B3
  }
  ::-webkit-scrollbar-thumb {
    background: #FFE5B3
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555
  }

.select-tag{
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
    outline: none;
    color: #725095;
}
.over-size{
    overflow-y: auto;
    height: 200px;
}

.outline-in{
outline: none;
border: none;
}