.report_head{
    font-size: 20px;
    font-weight: 'bold';
    color:#725095;
}
.report_details_text{
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: justify;
    font-size: 13px;
}
.more_info_head{
    color:#725095;
    font-size: 15px;
}

.textarea_div{
    height: 20px;
    width: 20px;
    background-color:'red'
}

.textArea{
    background-color: red;
    width:800px
}

textarea
{
  width:100%;
  border-radius: 5px;
  border-width: 1px;
  padding:10px;
  margin-top: 10px;
}

.internal_border{
    border-width: 1px;
    border-style: dashed;
    border-color:'#725095';
    align-items:center;
    justify-content:center;
    position: relative;
    height:150px;
}

.alignText{
  padding: 50px 0;
  /* border: 3px solid green; */
  text-align: center;
  /* display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%; */
    /* position: absolute;
    left: 40%;
    top: 40%; */
}

.drag_drop_title{
    font-size:16px;
    text-align: center;
    color:#725095;
}

.type_title{
    font-size:12px;
    text-align: center;
    color:#725095;
}

div.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }


  .loading{
    position: absolute;
    left: 50%;
    top: 40%;
  }
  .uploadedImage{
      height: 150px;
      width:'100%';
      object-fit: contain;
  }

  .grid-container {
    display: grid;
    /* grid-template-columns: auto auto auto auto; */
    margin: 1px;
  }
  .grid-item {
    text-align: center;
    height: 120px;
    width: 80%;
    background-color:#D19A2C;
    margin-bottom: 12px;
    border-radius: 5px;
    padding-top: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
  }

  .competitionTitle{
      font-weight: bold;
  }
  .competitionTitle2{
      font-size: 12px;
  }
  .dollerValue{
      font-size: 12px;
      font-weight: bold;
  }

  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .list{
    /* margin:5px; */
                padding:5px;
                background-color: white;
                width: 240px;
                /* height: auto; */
                max-height: 200px;
                overflow: auto;
                text-align:justify;
                border-width: 1px;
  }
  .listItem{
    cursor: pointer;
  }
  .btn{
    background-color:'red';
    height: 45px;
    width: 30px;
  }





.card1 {
  background-color: #D19A2C;
  color: white;
  /* padding: 1rem; */
  height: 15rem;
}

.cards {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
  margin-top: 20px;
}

/* Screen larger than 600px? 2 column */
@media (min-width: 600px) {
  .cards { grid-template-columns: repeat(2, 1fr); }
  /* .nbtn{margin-top: 10rem;} */
}

@media (max-width: 400px) {
  /* .cards { grid-template-columns: repeat(2, 1fr); } */
  .nbtn{margin-top: 10px;}
  /* .submit-btn{margin-left: 0px;} */
}

/* Screen larger than 900px? 3 columns */
@media (min-width: 900px) {
  .cards { grid-template-columns: repeat(3, 1fr); }
  /* .nbtn{margin-top: 10rem;} */
}