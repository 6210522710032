@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css');


.warning-msg{
    color: #9F6000;
    background-color: #FEEFB3;
  margin: 10px 0;
  padding: 10px;
  border-radius: 3px 3px 3px 3px;
 font-size: 10px;
}
