.padding-normal{
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

@media (max-width: 700px) {
    .padding-normal{
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    
}